import log from "loglevel";

export function userEvent(eventType: string, eventLabel: string) {
  // @ts-ignore
  if (typeof window === 'undefined' || !window.gtag) {
    log.debug('gtag not loaded');
    return;
  }
  // @ts-ignore
  const gtag = window.gtag;
  gtag('event', 'click', {
    'event_category': eventType,
    'event_label': eventLabel,
  });
}
