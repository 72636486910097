import axios from "axios";
import { serverHost } from "./env";

export function apiGet(url: string, params: any) {
  return axios.get(serverHost + url, params);
}

export function apiPost(url: string, params: any) {
  return axios.post(serverHost + url, params);
}
