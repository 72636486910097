let timeout: NodeJS.Timeout | null = null; // 如何有上一次的超时器就清理掉，保证输入防抖

export function debounce<T extends () => void>(
  func: T,
  wait: number
): () => void {
  return function () {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(), wait);
  };
}

export function extractUrls(text: string): string[] {
  // 正则表达式匹配 URL
  const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g;
  // 使用正则表达式查找所有匹配的 URL
  const matches = text.match(urlPattern);
  // 返回匹配结果，如果没有匹配的 URL，返回空数组
  return matches ? matches : [];
}
export function convertBytesToHuman(bytes: number): string {
  if (bytes === 0) {
    return '0 B';
  }

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = sizes[i];
  const humanReadableSize = (bytes / Math.pow(1024, i)).toFixed(2);

  return `${humanReadableSize} ${size}`;
}

export function decodeFakeBase64String(encoded: string, numChars: number): string {
  // 移除前面插入的随机字母
  encoded = encoded.substring(numChars);

  // 计算需要的填充字符数
  const mod = encoded.length % 4;
  if (mod !== 0) {
    const padLen = 4 - mod;
    encoded += '='.repeat(padLen);
  }

  // 解码 Base64 字符串
  try {
    const decodedBytes = atob(encoded);
    return decodedBytes;
  } catch (err) {
    throw new Error('Failed to decode base64 string');
  }
}

// 获取 URL 中的参数, 返回一个对象
function getUrlParams(url: string): Record<string, string> {
  const params: Record<string, string> = {};
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
}
