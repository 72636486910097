import { action, makeObservable } from "mobx";

export class AppStore {
  constructor() {
    makeObservable(this, {
      setSomething: action,
    });
  }

  setSomething() {
    // TODO: set something
  }
}
