import { Button, ConfigProvider, Input, message } from "antd";
import React, { createRef } from "react";
/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TinyColor } from "@ctrl/tinycolor";
import log from "loglevel";
import { extractUrls } from "../toolkits/common";
import DownloadCard from "./widget/DownloadCard";
import { VideoData } from "../toolkits/models";
import { appMode, appVersion } from "../toolkits/env";
import { AppMode } from "../toolkits/constant";
import { apiGet, apiPost } from "../toolkits/net";
import { userEvent } from "../toolkits/gtag";

interface Props {}

const colors = ["#FCB500", "#fd0054"];
const getHoverColors = (colors: string[]) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());
const getActiveColors = (colors: string[]) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());
const mainCss = css`
  .input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .input-link {
    width: 700px;
    height: 54px;
    margin: 0 10px 20px;
    border: 2px solid var(--bs-main);
  }

  .submit-btn {
    width: 200px;
    height: 54px;
    margin: 0 10px 20px;
  }
`;

class Downloader extends React.Component<Props> {
  state = {
    loading: false,
    downloadLink:
      appMode === AppMode.LOCAL
        ? "https://www.tiktok.com/@factory.girls/video/7348986752910069034"
        : "",
    videoData: undefined as unknown as VideoData,
    cardRef: createRef<DownloadCard>(),
  };

  componentDidMount(): void {
    apiGet("/api/version", {})
      .then((res) => {
        console.log("frontend version: ", appVersion);
        console.log("backend version: ", res.data.data);
      })
      .catch((res) => {});
  }

  submitClick() {
    const { downloadLink } = this.state;
    if (downloadLink === "") {
      message.error("Please enter a link");
      return;
    }
    const urls = extractUrls(downloadLink);
    if (urls.length === 0) {
      message.error("Invalid link");
      return;
    }
    this.setState({ loading: true, videoData: undefined });
    apiPost("/api/video/info", {
      url: downloadLink,
    })
      .then((res) => {
        if (res.status === 200 && res.data.code === "success") {
          userEvent("get_video_info_success", urls[0]);
          this.setState(
            {
              videoData: res.data.data,
              loading: false,
            },
            () => {
              this.state.cardRef?.current?.resetCover();
            }
          );
        } else {
          this.setState({ loading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        userEvent("get_video_info_error", urls[0]);
        message.error("Sever is to busy, please try again later");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render(): React.ReactNode {
    return (
      <div css={mainCss} className="download-container">
        <div className="input-box">
          <Input
            className="input-link"
            size="large"
            allowClear
            placeholder="Pase tiktok video link in here"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ downloadLink: e.target.value });
            }}
            defaultValue={this.state.downloadLink}
          />
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: `linear-gradient(135deg, ${colors.join(", ")})`,
                  colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(
                    colors
                  ).join(", ")})`,
                  colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(
                    colors
                  ).join(", ")})`,
                  lineWidth: 0,
                },
              },
            }}
          >
            <Button
              className="submit-btn"
              type="primary"
              size="large"
              onClick={this.submitClick.bind(this)}
            >
              Download Now
            </Button>
          </ConfigProvider>
        </div>
        {/* 加载视频信息 */}
        {this.state.videoData && (
          <DownloadCard
            videoData={this.state.videoData}
            loading={this.state.loading}
            ref={this.state.cardRef}
            downloadLink={this.state.downloadLink}
          />
        )}
      </div>
    );
  }
}

export default Downloader;
