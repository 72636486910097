import { Card, Image, Select, Divider, Button, Progress, Empty } from "antd";
import React from "react";
/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { VideoData } from "../../toolkits/models";
import { picturePlaceholder } from "../../img/icon";
import { DownloadOutlined } from "@ant-design/icons";
import log from "loglevel";
import {
  convertBytesToHuman,
  decodeFakeBase64String,
  extractUrls,
} from "../../toolkits/common";
import axios, { AxiosRequestConfig } from "axios";
import { userEvent } from "../../toolkits/gtag";

interface Props {
  videoData: VideoData;
  loading: boolean;
  downloadLink: string;
}

const mainCss = css`
  padding: 0 10px;

  .video-info {
    margin: 0 auto 20px;
    max-width: 700px;
    min-height: 220px;
  }

  .info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .ant-card-body {
    padding: 10px;
  }

  .empty-box {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .progress-box {
    max-width: 320px;
    margin: 0 auto 20px;
  }

  .info-content {
    text-align: left;
    padding: 0 10px;
    overflow: hidden;
    line-height: 36px;
    height: 200px;

    h3 {
      font-size: 18px;
      margin: 0;
    }
    p {
      margin: 10px 0;
      font-size: 20px;
      max-width: 350px;
      word-break: break-all;
    }
  }

  .download-button-group {
    height: 140px;
    width: 200px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  @media (min-width: 956px) {
    .video-info {
      max-width: 920px;
    }
  }

  @media (max-width: 956px) {
    .info-container {
      justify-content: center;
    }
    .info-content {
      margin-top: 20px;
    }
    .info-item {
      flex: 1 1 calc(100% - 10px);
    }
    .download-button-group {
      margin-bottom: 0;
    }
    .info-content {
      text-align: center;
      height: 100%;

      p {
        max-width: 100%;
      }
    }
  }
`;
let retryLimit = 3;

class DownloadCard extends React.Component<Props> {
  state = {
    isDownloading: false,
    notMarked: true,
    retryCount: 0,
    cover: "",
    progress: 0,
  };

  componentDidMount() {
    log.debug("DownloadCard mounted");
    this.props.videoData &&
      this.setState({ cover: this.props.videoData.cover });
  }

  download() {
    let me = this;
    if (this.state.isDownloading) {
      log.info("downloading");
      return;
    }
    this.setState({ isDownloading: true }, () => {
      const url = this.state.notMarked
        ? this.props.videoData.play
        : this.props.videoData.wmplay;
      // 模拟下载·
      axios
        .get(url)
        .then((response) => {
          me.setState({ progress: 0 });
        })
        .catch((error) => {
          log.debug(error);
        })
        .finally(() => {
          // 真正调用下载的处理
          const urls = extractUrls(this.props.downloadLink);
          urls.length > 0 && userEvent("download_tiktok_video", urls[0]);
          axios({
            url: decodeFakeBase64String(url, 21),
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent: {
              total: any;
              loaded: any;
            }) => {
              const total = progressEvent.total;
              const current = progressEvent.loaded;
              const percentCompleted = Math.round((current / total) * 100);
              me.setState({ progress: percentCompleted });
              log.debug(`${percentCompleted}%`);
            },
          } as AxiosRequestConfig)
            .then((response) => {
              const urlBlob = window.URL.createObjectURL(
                new Blob([response.data])
              );
              const link = document.createElement("a");
              const watermark = this.state.notMarked
                ? "_not_watermark"
                : "_has_watermark";
              const name = new Date().getTime() + watermark + ".mp4";
              link.href = urlBlob;
              link.setAttribute("download", name);
              document.body.appendChild(link);
              link.click();
              // @ts-ignore
              link.parentNode.removeChild(link);
              me.setState({ isDownloading: false, progress: 0 });
            })
            .catch((error) => {
              log.error(error);
            })
            .finally(() => {
              me.setState({ isDownloading: false, progress: 0 });
            });
        });
    });
  }

  handleImageError() {
    const { retryCount } = this.state;
    const cover = this.props.videoData.cover;
    if (retryCount < retryLimit) {
      log.info(`Failed to load image, retrying ${this.state.retryCount}`);
      setTimeout(() => {
        this.setState({
          retryCount: retryCount + 1,
          cover: `${cover}?retry=${retryCount + 1}`,
        });
      }, 200);
    } else {
      console.log(`Failed to load image after ${retryLimit} attempts`);
      this.setState({ cover: picturePlaceholder });
    }
  }

  resetCover() {
    this.setState({ cover: this.props.videoData.cover });
  }

  render(): React.ReactNode {
    let downloadOpts: any[] = [];
    if (this.props.videoData.size > 0) {
      downloadOpts.push({
        label: "Not Marked  " + convertBytesToHuman(this.props.videoData.size),
        value: "NM",
      });
    }
    if (this.props.videoData.wm_size > 0) {
      downloadOpts.push({
        label:
          "WaterMarked  " + convertBytesToHuman(this.props.videoData.wm_size),
        value: "WM",
      });
    }

    return (
      <div css={mainCss}>
        {this.state.isDownloading && (
          <Progress percent={this.state.progress} className="progress-box" />
        )}

        <Card className="video-info" loading={this.props.loading}>
          {this.props.videoData && this.props.videoData ? (
            <div className="info-container">
              <div
                className="info-item"
                style={{ width: 150, textAlign: "center" }}
              >
                <Image
                  height={200}
                  src={this.state.cover}
                  fallback={picturePlaceholder}
                  onError={this.handleImageError.bind(this)}
                />
              </div>
              <div className="info-content info-item">
                {/* @ts-ignore */}
                <h3>{this.props.videoData.author_nickname}</h3>
                <p>{this.props.videoData.title}</p>
              </div>
              <div className="download-button-group info-item">
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(value: string) => {
                    this.setState({ notMarked: value === "NM" });
                    const urls = extractUrls(this.props.downloadLink);
                    urls.length > 0 &&
                      userEvent("select_tiktok_type_" + value, urls[0]);
                  }}
                  options={downloadOpts}
                  placeholder="Select a quality"
                  defaultValue={this.state.notMarked ? "NM" : "WM"}
                />
                <Divider />
                <Button
                  style={{ width: "100%" }}
                  icon={<DownloadOutlined />}
                  onClick={this.download.bind(this)}
                  size="large"
                  disabled={this.state.isDownloading}
                >
                  Download
                </Button>
              </div>
            </div>
          ) : (
            <div className="empty-box">
              <p style={{ fontSize: "16px", color: "var(--gray-8)" }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={false}
                />
              </p>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default DownloadCard;
