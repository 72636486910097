import React from "react";
import { Layout } from "antd";
import GlobalCss from "./GlobalCss";
/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Downloader from "./Downloader";

const mainCss = css`
  text-align: center;
  min-height: 100px;
  background-color: var(--gray-1);
`;

interface Props {}

class App extends React.Component<Props> {
  componentDidMount(): void {}

  render(): React.ReactNode {
    return (
      <Layout css={mainCss}>
        <GlobalCss />
        <Downloader />
      </Layout>
    );
  }
}

export default App;
