import React from "react";
import { Global, css } from "@emotion/react";

class GlobalCss extends React.Component<any> {
  render(): React.ReactNode {
    return (
      <Global
        styles={css``}
      />
    );
  }
}

export default GlobalCss;
