import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "mobx-react";
import { appMode } from "./toolkits/env";
import { AppMode } from "./toolkits/constant";
import log from "loglevel";
import { AppStore } from "./store/app";

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);
const appStore = new AppStore();

if (appMode === AppMode.PROD) {
  log.setLevel(log.levels.INFO);
  log.setDefaultLevel(log.levels.INFO);
} else {
  log.setLevel(log.levels.TRACE);
}

root.render(
  <React.StrictMode>
    <Provider appStore={appStore}>
      {/* @ts-ignore */}
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
